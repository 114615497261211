.variant-input .input {
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: #979ea8;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  border: none;
  line-height: 25px;
  padding: 2px 26px 1px 8px;
}
.variant-input .input::-webkit-input-placeholder {
  color: #979ea8;
  opacity: 0.5;
}
.variant-input .input:-moz-placeholder {
  color: #979ea8;
  opacity: 0.5;
}
.variant-input .input-prefix {
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: #979ea8;
  top: 9px;
  position: absolute;
  left: 8px;
}
.variant-input .input-control {
  position: absolute;
  top: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  right: 6px;
  -webkit-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}
.variant-input .input-control.clear {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-delete.png) no-repeat 0 0;
}
.variant-input .input-control.clear:hover {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-delete-hover.png) no-repeat 0 0;
}
.variant-input .input-control.target {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-target.png) no-repeat 0 0;
}
.variant-input .input-control.target:hover {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-target-hover.png) no-repeat 0 0;
}
.variant-input .input-control.target:hover .use-url-popup {
  bottom: 27px;
  opacity: 1;
  visibility: visible;
}
.variant-input .input-control.target .use-url-popup {
  opacity: 0;
  visibility: hidden;
  bottom: 19px;
  width: 168px;
  background-color: #363234;
  border-radius: 4px;
  left: 50%;
  margin-left: -84px;
  position: absolute;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, bottom 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, bottom 0.2s ease-in-out;
}
.variant-input .input-control.target .use-url-popup.hidden {
  opacity: 0;
  visibility: hidden;
  bottom: 19px;
}
.variant-input .input-control.target .use-url-popup li {
  height: 34px;
  line-height: 34px;
  display: block;
  font-size: 14px;
  color: white;
  text-align: center;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  -webkit-transition: color 0.2s ease-out;
  transition: color 0.2s ease-out;
}
.variant-input .input-control.target .use-url-popup li:hover {
  color: #1ce4c8;
}
.variant-input .input-control.target .use-url-popup li:after {
  content: '';
  display: block;
  height: 1px;
  background-color: #262324;
}
.variant-input .input-control.target .use-url-popup li:last-child:after {
  display: none;
}
.variant-input .input-control.target .use-url-popup .corner-wrapper {
  position: absolute;
  left: 84px;
  width: 16px;
  height: 8px;
  bottom: -8px;
  margin-left: -8px;
  clip: rect(0, 115px, 107px, -99px);
}
.variant-input .input-control.target .use-url-popup .corner-wrapper .corner {
  position: absolute;
  width: 11px;
  height: 11px;
  left: 2px;
  top: -6px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04), 0 0 0 3px rgba(255, 255, 255, 0.28);
  background: rgba(243, 243, 243, 0.98);
}
.variant-input .input-control.target .use-url-popup .corner-wrapper .corner {
  background-color: #363234;
  box-shadow: none;
  margin-left: 0;
}
.variant-input .input-control.target .use-url-popup .hover-zone {
  width: 60px;
  height: 15px;
  bottom: -15px;
  left: 50%;
  margin-left: -30px;
  position: absolute;
}
.variant-input .input-control.apply {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-go.png) no-repeat 0 0;
}
.variant-input .input-control.apply:hover {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-go-hover.png) no-repeat 0 0;
}
.variant-input .input-control.hidden {
  display: none;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
.variant-input .input-control.clear {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-delete@2x.png) no-repeat 0 0;
    background-size: 18px 18px;
}
.variant-input .input-control.clear:hover {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-delete-hover@2x.png) no-repeat 0 0;
    background-size: 18px 18px;
}
.variant-input .input-control.target {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-target@2x.png) no-repeat 0 0;
    background-size: 18px 18px;
}
.variant-input .input-control.target:hover {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-target-hover@2x.png) no-repeat 0 0;
    background-size: 18px 18px;
}
.variant-input .input-control.apply {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-go@2x.png) no-repeat 0 0;
    background-size: 18px 18px;
}
.variant-input .input-control.apply:hover {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-go-hover@2x.png) no-repeat 0 0;
    background-size: 18px 18px;
}
}
