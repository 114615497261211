.cart-settings-panel {
  width: 168px;
  height: 360px;
  box-sizing: border-box;
  padding: 13px 15px;
  right: 47px !important;
  top: -94px;
  border-radius: 8px;
  background-color: rgba(244, 244, 244, 0.96);
  border: 1px solid rgba(64, 64, 64, 0.08);
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu, 'Fira Sans', Roboto, 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  color: #282828;
}
.cart-settings-panel .title-row {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
}
.cart-settings-panel .title-row .title {
  position: relative;
  top: -4px;
  left: -1px;
  letter-spacing: -0.4px;
}
.cart-settings-panel .styling-state-switcher {
  margin-top: 24px;
  margin-bottom: 13px;
}
.cart-settings-panel .styling-state-switcher .btn {
  color: #808080;
}
.cart-settings-panel .styling-state-switcher .btn:hover {
  cursor: pointer;
}
.cart-settings-panel .styling-state-switcher .btn.active {
  color: #282828;
}
.cart-settings-panel .styling-state-switcher .btn + span {
  margin-left: 9px;
}
.cart-settings-panel .settings-panel .row {
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.cart-settings-panel .settings-panel .row:last-child {
  border-bottom: none;
}
.cart-settings-panel .settings-panel .row .color-picker-container.round {
  width: 26px;
  height: 26px;
}
.cart-settings-panel .settings-panel .row .num-input {
  padding: 0;
  border: 0;
  font-size: 24px;
  color: #282828;
  background: transparent;
  text-align: right;
  cursor: ns-resize;
  transition: all 0.1s ease-out;
}
.cart-settings-panel .settings-panel .row .font-family-container .expand-arrow {
  width: 7px;
  height: 12px;
  background-size: 12px 7px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/cart_settings/expand-arrow.svg);
  flex: 0 0 12px;
  position: relative;
  top: 3px;
  left: 1px;
}
.cart-settings-panel .settings-panel .row .font-family-container:hover .expand-arrow {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/cart_settings/expand-arrow-active.svg);
}
