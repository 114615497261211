.controls .control .picture-alt[data-v-60cc4a0c] {
  height: 162px;
  top: -66px;
  box-sizing: border-box;
  width: 167px;
  right: 47px;
  padding: 10px 16px 4px 16px;
  border-radius: 9px;
  background-color: #f2f2f2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.0666);
  letter-spacing: -0.08px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: #282828;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.controls .control .picture-alt .param-caption[data-v-60cc4a0c] {
  font-weight: 500;
  text-align: left;
  padding-bottom: 15px;
  color: #282828;
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 20px;
  position: relative;
  left: 0;
  width: 100%;
}
.controls .control .picture-alt .param-caption .question-icon[data-v-60cc4a0c] {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 0;
  margin-top: -2px;
  z-index: 2;
  right: 0;
}
.controls .control .picture-alt .param-caption .question-icon circle[data-v-60cc4a0c] {
  fill: transparent;
  -webkit-transition: fill 0.2s ease-out;
  transition: fill 0.2s ease-out;
}
.controls .control .picture-alt .param-caption .question-icon path[data-v-60cc4a0c] {
  fill: #b8b8b8;
  -webkit-transition: fill 0.2s ease-out;
  transition: fill 0.2s ease-out;
}
.controls .control .picture-alt .param-caption .question-icon:hover circle[data-v-60cc4a0c] {
  fill: #808080;
}
.controls .control .picture-alt .param-caption .question-icon:hover path[data-v-60cc4a0c] {
  fill: #fff;
}
.controls .control .picture-alt .param[data-v-60cc4a0c] {
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 112px;
}
.controls .control .picture-alt .param textarea[data-v-60cc4a0c] {
  position: relative;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  letter-spacing: -0.08px;
  padding: 7px 15px 4px 8px;
  margin: 0;
  margin-left: -9px;
  overflow-y: hidden;
  overflow-x: hidden;
  outline: none;
  border: 1px solid rgba(184, 184, 184, 0.24);
  border-color: transparent;
  resize: none;
  height: 100%;
  width: calc(100% + 18px);
  background-color: transparent;
  color: #282828;
  line-height: 16px;
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.controls .control .picture-alt .param textarea[data-v-60cc4a0c]:focus {
  background-color: white;
  border: 1px solid rgba(184, 184, 184, 0.24);
}
.controls .control .picture-alt .param textarea[data-v-60cc4a0c]::placeholder {
  color: #b8b8b8;
}
