.rm-opacity-slider {
  border-radius: 15px;
  cursor: pointer;
  position: absolute;
  top: 39px;
  left: 50%;
  margin-left: -60px;
  width: 120px;
  height: 30px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/picture_settings/opacity-bg.png);
  background-repeat: no-repeat;
  background-size: 120px 30px;
}
.rm-opacity-slider .opacity-handle {
  cursor: hand;
  cursor: ew-resize;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px #3d3d3d;
  position: absolute;
  width: 26px;
  height: 26px;
  top: 0px;
  left: 0px;
  margin: 2px 0 0 -13px;
  -webkit-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
}
.rm-opacity-slider .opacity-handle:hover,
.rm-opacity-slider .opacity-handle.opacity-dragging {
  box-shadow: inset 0 0 0 2px #0078ff;
}
