#rm-footer {
  background: #282828;
  height: 100%;
  width: 100%;
}
#rm-footer.transform footer {
  padding: 0 !important;
}
#rm-footer footer {
  margin: 0 auto;
  width: 1024px;
  height: 100%;
  padding: 58px 20px 16px;
  box-sizing: border-box;
}
#rm-footer .footer-top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 38px;
}
#rm-footer .title {
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 24px;
  color: #808080;
  border-bottom: 1px solid rgba(128, 128, 128, 0.24);
  padding-bottom: 6px;
  margin-bottom: 16px;
}
#rm-footer .company {
  width: 230px;
}
#rm-footer .support {
  width: 230px;
}
#rm-footer .updates {
  width: 482px;
}
#rm-footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#rm-footer ul li a {
  color: #fff;
  text-decoration: none;
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 24px;
  display: inline-block;
}
#rm-footer ul li a:hover {
  color: #ec520b;
}
#rm-footer .row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
#rm-footer .row ul:last-child {
  margin-right: 30px;
}
#rm-footer .footer-bottom {
  display: flex;
  align-items: flex-start;
  color: #808080;
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.1px;
  line-height: 24px;
}
#rm-footer .footer-bottom a {
  color: #808080;
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-decoration: none;
}
#rm-footer .footer-bottom a:hover {
  color: #ec520b;
}
#rm-footer .footer-bottom a:after {
  content: ', ';
}
#rm-footer .footer-bottom a:last-child:after {
  content: none;
}
#rm-footer .footer-bottom .inc {
  width: 252px;
}
.rm-footer-mobile .mobile-no-margin {
  margin: 0 !important;
}
.isphone .rm-footer-mobile #rm-footer {
  padding-right: 4px;
  margin-left: -4px;
  padding-left: 4px;
}
footer .rm-footer-mobile #rm-footer {
  padding-right: 0 !important;
  margin-left: 0 !important;
  padding-left: 0 !important;
}
.rm-footer-mobile #rm-footer footer {
  width: 100%;
}
.rm-footer-mobile #rm-footer .footer-top {
  display: block;
  margin-bottom: 76px;
}
.rm-footer-mobile #rm-footer .footer-top > div {
  width: 100%;
  display: block;
}
.rm-footer-mobile #rm-footer .row {
  display: block;
}
.rm-footer-mobile #rm-footer .row ul:last-child {
  margin-right: 0;
}
.rm-footer-mobile #rm-footer ul {
  margin-bottom: 36px;
}
.rm-footer-mobile #rm-footer .footer-bottom {
  display: block;
}
.rm-footer-mobile #rm-footer .footer-bottom > div {
  width: 100%;
  line-height: 1;
}
.rm-footer-full-width:before {
  content: '';
  top: 0;
  right: 99%;
  width: 100%;
  height: 100%;
  background: #282828;
  display: block;
  position: absolute;
}
.rm-footer-full-width:after {
  content: '';
  top: 0;
  left: 99%;
  width: 100%;
  height: 100%;
  background: #282828;
  display: block;
  position: absolute;
}
.widget-rm-footer {
  background: #282828;
}
@media screen and (max-width: 767px) and (orientation: portrait) {
.mobile-no-margin {
    margin: 0 !important;
}
.isphone #rm-footer {
    padding-right: 4px;
    margin-left: -4px;
    padding-left: 4px;
}
footer #rm-footer {
    padding-right: 0 !important;
    margin-left: 0 !important;
    padding-left: 0 !important;
}
#rm-footer footer {
    width: 100%;
}
#rm-footer .footer-top {
    display: block;
    margin-bottom: 76px;
}
#rm-footer .footer-top > div {
    width: 100%;
    display: block;
}
#rm-footer .row {
    display: block;
}
#rm-footer .row ul:last-child {
    margin-right: 0;
}
#rm-footer ul {
    margin-bottom: 36px;
}
#rm-footer .footer-bottom {
    display: block;
}
#rm-footer .footer-bottom > div {
    width: 100%;
    line-height: 1;
}
}
