.video_settings .icon {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/icon.png) no-repeat 0 0;
  background-size: 30px 30px;
}
.video_settings .icon:hover {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/icon-hover.png);
}
.control-panel {
  position: absolute;
  top: -38px;
  bottom: auto !important;
  right: 42px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 152px;
  line-height: 1.4;
  border-radius: 9px;
  background-color: rgba(242, 242, 242, 0.95);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.0666);
  overflow: hidden;
  color: #5d5d5d;
  font-size: 12px;
  font-weight: 600;
}
.control-panel.overflow-visible {
  overflow: visible;
}
.control-panel .param-caption {
  position: absolute;
  left: 0;
  top: 11px;
  width: 100%;
  text-align: center;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.control-panel .param-input {
  position: absolute;
  top: 30px;
  left: 50%;
  font-size: 34px;
  text-align: center;
  width: 66px;
  color: #5d5d5d;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  padding: 0;
  border: 0;
  outline: 0;
  margin: 0;
  -moz-appearance: none;
  background: transparent;
  margin-left: -33px;
}
.control-panel .param-component {
  position: relative;
  height: 80px;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out, height 0.2s ease-in-out;
}
.control-panel .separator {
  position: absolute;
  width: 139px;
  bottom: 0;
  height: 0px;
  left: 50%;
  margin-left: -70px;
  border-top: 1px dotted rgba(93, 93, 93, 0.2);
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.control-panel.uploading .picture_preloader.rmpreloader {
  visibility: visible;
  opacity: 1;
}
.control-panel.uploading .upload {
  visibility: hidden;
  opacity: 0;
}
.control-panel.uploading .loading {
  visibility: visible;
  opacity: 1;
}
.control-panel .picture_preloader.rmpreloader {
  position: absolute;
  top: 50%;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  z-index: 100;
  margin-left: -22px;
  margin-top: -22px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.control-panel .picture_preloader.rmpreloader .arc {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/preloader-dark.png);
  background-size: 38px 38px;
}
.control-panel .picture_preloader.rmpreloader .shadow {
  display: none;
}
.control-panel .loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  border-radius: 8px 8px 0 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.control-panel .loading .mask {
  -webkit-transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/background/mask-stub.png) repeat 0 0;
  border-radius: 7px;
}
.control-panel .loading .delete {
  position: absolute;
  bottom: 16px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  left: 50%;
  margin-left: -9px;
  background-size: 18px 18px !important;
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/background/delete.png) no-repeat 0 0;
}
.control-panel .loading .delete:hover {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/background/delete-hover.png) no-repeat 0 0;
}
.control-panel .upload {
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, Arial, sans-serif;
}
.control-panel .upload .uploadInput {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}
.control-panel .upload .upload-wrapper {
  position: absolute;
  top: 41px;
  left: 50%;
  -webkit-transition: top 0.4s ease-in-out;
  transition: top 0.4s ease-in-out;
  pointer-events: none;
}
.control-panel .upload .upload-wrapper .center-wrapper {
  position: relative;
  left: -50%;
  cursor: pointer;
  pointer-events: all;
}
.control-panel .upload .upload-wrapper .center-wrapper:hover .icon {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/slideshow/upload-hover.png) no-repeat 0 0;
}
.control-panel .upload .upload-wrapper .center-wrapper .icon {
  width: 81px;
  height: 60px;
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/slideshow/upload.png) no-repeat 0 0;
  margin: 0 auto;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: relative;
}
.control-panel .upload .upload-wrapper .center-wrapper .caption-upload-dop {
  font-size: 12px;
  margin-top: 7px;
  text-align: center;
  white-space: nowrap;
  height: 16px;
  -webkit-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  opacity: 0.4;
}
.control-panel .upload .upload-wrapper .center-wrapper .caption-upload-dop-light {
  color: #fff;
  opacity: 0.9;
}
.control-panel.dragging .upload .upload-wrapper .icon {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/slideshow/upload-hover.png) no-repeat 0 0;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
.control-panel.dragging .upload .upload-wrapper .icon {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/slideshow/upload-hover@2x.png);
    background-size: 81px 60px;
}
.control-panel .upload .upload-wrapper .center-wrapper .icon {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/slideshow/upload@2x.png);
    background-size: 81px 60px;
}
.control-panel .upload .upload-wrapper .center-wrapper:hover .icon {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/slideshow/upload-hover@2x.png);
    background-size: 81px 60px;
}
.control-panel .loading .delete {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/background/delete@2x.png) no-repeat 0 0;
}
.control-panel .loading .delete:hover {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/background/delete-hover@2x.png) no-repeat 0 0;
}
.control-panel .picture_preloader.rmpreloader .arc {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/preloader-dark@2x.png);
}
}
.video-panel {
  height: 160px;
  -webkit-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
  padding: 11px 15px 15px;
}
.video-panel.error {
  height: 153px;
}
.video-panel.loaded,
.video-panel.loading {
  height: 295px;
  border-radius: 9px;
}
.video-panel.loaded.vimeo,
.video-panel.loading.vimeo {
  height: 353px;
}
.video-panel.loaded.custom-thumb,
.video-panel.loading.custom-thumb {
  height: 360px;
}
.video-panel.loaded.vimeo.custom-thumb,
.video-panel.loading.vimeo.custom-thumb {
  height: 418px;
}
.video-panel-header {
  text-align: center;
  margin-bottom: 14px;
}
.video-panel-settings {
  position: absolute;
  top: 0;
  padding: 12px 15px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.video-panel-setting {
  position: relative;
  height: 20px;
  padding-right: 46px;
  margin: 10px 0;
}
.video-panel-setting:first-child {
  margin-top: 0;
}
.video-panel-setting:last-child {
  margin-bottom: 0;
}
.video-panel-setting.thumb {
  z-index: 1;
}
.video-panel-setting-caption {
  color: #f2f2f2;
  line-height: 20px;
}
.video-panel-switcher {
  position: absolute;
  top: 0;
  right: 0;
}
.video-panel-dropzone-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 103px;
}
.video-panel-dropzone-container .video-panel-icon-replace {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto;
  top: 40px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/replace-thumbnail.png);
  background-size: auto 90%;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: 0.4;
  display: none;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.video-panel-dropzone-container:hover .video-panel-icon-replace {
  opacity: 0.9;
}
.video-panel-dropzone-container .caption-upload-dop {
  display: none;
  opacity: 0;
}
.video-panel-thumb-upload-container {
  margin: 0;
  padding: 0;
  height: 48px;
  width: 100%;
  position: absolute;
  top: 5px;
}
.video-panel-thumb-preloader {
  position: absolute;
  top: 40px;
  width: 100%;
}
.video-panel-thumb-preloader > .rmpreloader {
  margin: 0 auto;
}
.video-panel-preview-wrapper {
  position: absolute;
  left: 0;
  top: 160px;
  width: 100%;
  height: 135px;
  background-color: #282828;
}
.video-panel.vimeo .video-panel-preview-wrapper {
  height: 193px;
}
.video-panel-preview-wrapper.slide-leave-to {
  visibility: hidden;
}
.video-panel-preview-wrapper.slide-leave-active {
  -webkit-transition: visibility 0s ease-out 0.3s;
  transition: visibility 0s ease-out 0.3s;
}
.video-panel-preview-wrapper.slide-enter,
.video-panel-preview-wrapper.slide-leave-active,
.video-panel-preview-wrapper.slide-enter-active {
  overflow: hidden;
}
.video-panel-preview {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #282828;
  height: 135px;
  border-radius: 0 0 7px 7px;
  transform: none;
  -webkit-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transition: height 0.3s ease-in-out;
  transition: height 0.3s ease-in-out;
}
.video-panel.vimeo .video-panel-preview {
  height: 193px;
}
.video-panel.custom-thumb .video-panel-preview {
  height: 200px;
}
.video-panel.vimeo.custom-thumb .video-panel-preview {
  height: 258px;
}
.slide-enter .video-panel-preview,
.slide-leave-to .video-panel-preview {
  transform: translateY(-100%);
}
.video-panel-preview .uploadInput {
  width: 0;
  height: 0;
  visibility: hidden;
}
.video-panel-preview-caption {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 110px;
  display: block;
  display: -webkit-box;
  width: 122px;
  height: 33.6px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
.video-panel-preview-caption .video-panel-preview-caption-private {
  line-height: 33.6px;
}
.video-panel-preview-logo {
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.video-panel-preview-logo.vimeo {
  width: 46px;
  height: 13px;
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/vimeo.png) no-repeat 0 0;
  background-size: 46px 13px;
}
.video-panel-preview-logo.youtube {
  width: 40px;
  height: 17px;
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/youtube.png) no-repeat 0 0;
  background-size: 40px 17px;
}
.video-panel-preview-image-container {
  position: absolute;
  top: 97px;
  left: 0;
  height: 103px;
  width: 100%;
  border-radius: 0 0 7px 7px;
  -webkit-transition: background 0.2s ease-in;
  transition: background 0.2s ease-in;
}
.video-panel-preview-image-container.vimeo {
  top: 157px;
  height: 101px;
}
.video-panel-preview-image-container.dragging {
  background-color: #444444;
}
.video-panel-preview-image-wrapper {
  width: 100%;
  height: 100%;
  border-radius: 0 0 13px 13px;
  overflow: hidden;
}
.video-panel-preview-image {
  width: 100%;
  height: 100%;
  padding: 1px;
  border-radius: 0 0 13px 13px;
  margin-top: -1px;
  margin-left: -1px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: auto 150%;
  -webkit-transition: background 0.5s ease-in;
  transition: background 0.5s ease-in;
}
.video-panel-preview-mask {
  -webkit-transform: translate3d(0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0 0 13px 13px;
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/mask.png) no-repeat 0 0;
  opacity: 0.9;
}
.video-panel-preview-preloader {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -22px;
  margin-left: -22px;
}
.video-panel-preview-preloader .shadow {
  display: none;
}
.video-panel-error .error-text {
  width: 122px;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
.video_settings .icon {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/icon@2x.png);
}
.video_settings .icon:hover {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/icon-hover@2x.png);
}
.video-panel-preview-logo.vimeo {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/vimeo@2x.png);
}
.video-panel-preview-logo.youtube {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/youtube@2x.png);
}
.video-panel-preview-mask {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/mask@2x.png);
    background-size: cover;
}
}
