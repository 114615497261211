.panel.setting-panel {
  width: 168px;
  height: 248px;
  box-sizing: border-box;
  position: absolute;
  top: -254px;
  right: 16px;
  padding: 13px 15px;
  border-radius: 8px;
  background-color: rgba(244, 244, 244, 0.96);
  border: 1px solid rgba(64, 64, 64, 0.08);
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu, 'Fira Sans', Roboto, 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  color: #282828;
}
.panel.setting-panel .panel-header {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 13px;
  font-size: 16px;
  text-align: left;
  letter-spacing: -0.4px;
}
.panel.setting-panel .row {
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.panel.setting-panel .row .color-picker-container.round {
  width: 26px;
  height: 26px;
}
.panel.setting-panel .row .font-family-container .expand-arrow {
  width: 7px;
  height: 12px;
  background-size: 12px 7px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/add_to_cart_settings/expand-arrow.svg);
  flex: 0 0 12px;
  position: relative;
  top: 3px;
  left: 1px;
}
.panel.setting-panel .row .font-family-container.container-disabled:hover .expand-arrow {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/add_to_cart_settings/expand-arrow.svg);
}
.panel.setting-panel .row .font-family-container:hover .expand-arrow {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/add_to_cart_settings/expand-arrow-active.svg);
}
.panel.setting-panel .row .font-style-container .expand-arrow {
  width: 7px;
  height: 12px;
  background-size: 12px 7px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/add_to_cart_settings/expand-arrow.svg);
  flex: 0 0 12px;
  position: relative;
  top: 3px;
  left: 1px;
}
.panel.setting-panel .row .font-style-container:hover .expand-arrow {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/add_to_cart_settings/expand-arrow-active.svg);
}
.panel.setting-panel .row .font-style-container .box_container {
  left: -10px;
}
