#mobile-header {
  display: none;
}
.constructor:not(.preview) #mobile-header {
  display: none !important;
}
#mobile-header .hamburger {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  margin: 6px 6px;
  z-index: 99999999;
}
#mobile-header .hamburger .open-menu {
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 50%;
  border: none;
  padding: 0;
  display: inline-block;
}
#mobile-header .hamburger .open-menu span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
#mobile-header .hamburger svg {
  width: 16px;
  height: 16px;
}
#mobile-header .inner {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  transform: translateX(100%);
  background: #fff;
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
#mobile-header .links {
  box-sizing: border-box;
  padding: 68px 20px;
  width: 100%;
}
#mobile-header .links a {
  display: block;
  color: #000;
  text-decoration: none;
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  letter-spacing: -2.4px;
  margin-bottom: 40px;
}
#mobile-header .links a.active {
  color: #ec520b;
  cursor: default;
  pointer-events: none;
}
#mobile-header .links a:hover {
  color: #ec520b;
}
#mobile-header.opened .inner {
  transform: translateX(0);
}
.rm-header-mobile #mobile-header {
  display: block;
}
@media screen and (max-width: 768px) {
#mobile-header {
    display: block;
}
}
