.rmswitcher-body {
  user-select: none;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
.rmswitcher-panel {
  position: relative;
  top: 0;
}
.rmswitcher-panel-on,
.rmswitcher-panel-off {
  position: absolute;
  top: 0;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
.rmswitcher-panel-slider {
  position: absolute;
  top: 1px;
  left: 50%;
}
