.selector[data-v-bbabfa86] {
  position: relative;
  margin: 12px auto 0;
  width: 100%;
  height: 32px;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.12);
  color: #44444c;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}
.selector[data-v-bbabfa86]:hover {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.24);
}
.selector:hover .popup-arrow[data-v-bbabfa86] {
  opacity: 1;
}
.selector:hover .popup-arrow-big path[data-v-bbabfa86] {
  fill: #282828;
}
.selector .caption[data-v-bbabfa86] {
  text-align: center;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
}
.selector .caption-inner[data-v-bbabfa86] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 100%;
  padding: 0 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.selector .popup-arrow[data-v-bbabfa86] {
  position: absolute;
  top: 13px;
  right: 9px;
  width: 0;
  height: 0;
  border-right: 3px solid transparent;
  border-left: 3px solid transparent;
  border-top: 4px solid #44444c;
  opacity: 0.24;
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
}
.selector .popup-arrow-big[data-v-bbabfa86] {
  position: absolute;
  right: 0px;
  top: 50%;
  margin-right: 6px;
  transform: translateY(-50%);
}
.selector .popup-arrow-big path[data-v-bbabfa86] {
  fill: #b8b8b8;
  -webkit-transition: fill 0.1s ease-out;
  transition: fill 0.1s ease-out;
}
.selector.opened .types-popup[data-v-bbabfa86] {
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
  visibility: visible;
}
.selector .types-popup[data-v-bbabfa86] {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.0666);
  background: rgba(255, 255, 255, 0.96);
  border-radius: 3px;
  text-align: left;
  width: 100%;
}
.selector .types-popup .type-item[data-v-bbabfa86] {
  position: relative;
  padding: 8px 13px 8px 21px;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
}
.selector .types-popup .type-item .point[data-v-bbabfa86] {
  position: absolute;
  width: 6px;
  height: 6px;
  left: 9px;
  top: 12px;
}
.selector .types-popup .type-item:hover .point[data-v-bbabfa86] {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/point-hover.png) no-repeat 0 0;
}
.selector .types-popup .type-item.curr .point[data-v-bbabfa86] {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/point-active.png) no-repeat 0 0 !important;
}
.selector .types-popup .type-item.disabled[data-v-bbabfa86] {
  opacity: 0.7;
  cursor: default;
}
.selector .types-popup .type-item.disabled:hover .point[data-v-bbabfa86] {
  background: none;
}
