.font-style-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.font-style-container .example {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  flex: 0 1 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 16px;
  cursor: pointer;
}
.font-style-container .box_container {
  -webkit-transform: translate3d(0, 0, 0);
  position: absolute;
  z-index: 1;
  display: none;
  width: 152px;
  top: 27px;
  left: -18px;
  border: 1px solid #dddddd;
  background: white;
  cursor: pointer;
}
.font-style-container .box_container.from-top {
  top: auto;
  bottom: 27px;
}
.font-style-container .box_container.visible {
  display: block;
}
.font-style-container .box_container .font-elem {
  padding: 7px 23px 8px;
  position: relative;
}
.font-style-container .box_container .font-elem .point {
  position: absolute;
  width: 6px;
  height: 6px;
  left: 10px;
  top: 12px;
}
.font-style-container .box_container .font-elem:hover .point {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/text_styles/point-hover.png) no-repeat 0 0;
}
.font-style-container .box_container .font-elem.curr .point {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/text_styles/point-active.png) no-repeat 0 0 !important;
}
.font-style-container .expand-arrow {
  flex: 0 0 17px;
  width: 7px;
  height: 5px;
  background-repeat: no-repeat;
  background-size: 7px 5px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/button_settings/arrow.png);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  cursor: pointer;
}
.font-style-container:hover .expand-arrow {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/button_settings/arrow-active.png);
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
.font-style-container .expand-arrow {
    background-size: 7px 5px;
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/button_settings/arrow@2x.png);
}
.font-style-container:hover .expand-arrow {
    background-size: 7px 5px;
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/button_settings/arrow-active@2x.png);
}
.font-style-container .box_container .font-elem:hover .point {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/text_styles/point-hover@2x.png);
    background-size: 6px 6px;
}
.font-style-container .box_container .font-elem.curr .point {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/text_styles/point-active@2x.png) !important;
    background-size: 6px 6px !important;
}
}
