.color-picker-container {
  position: relative;
  width: 36px;
  height: 20px;
  border-radius: 10px;
}
.color-picker-container.round {
  width: 28px;
  height: 28px;
  border-radius: 100%;
}
.color-picker-container.round .arrow-icon {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/shape_settings/color-arrow.png);
  background-repeat: no-repeat;
  background-color: white;
}
.color-picker-container.round:hover .arrow-icon {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/shape_settings/color-arrow-hover.png);
}
.color-picker-container .opacity-grid,
.color-picker-container .color-preview {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
}
.color-picker-container .opacity-grid {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/text_link/opacity-grid.png) 0 0;
}
.color-picker-container .colorbox_container {
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  -webkit-transform: translate3d(0, 0, 0);
  position: fixed;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  width: 152px;
  height: 240px;
}
.color-picker-container .colorbox_container.visible {
  visibility: visible;
  opacity: 1;
}
.color-picker-container .color-preview,
.color-picker-container .arrow-icon {
  cursor: pointer;
}
.color-picker-container.color-picker-disabled .color-preview,
.color-picker-container.color-picker-disabled .arrow-icon {
  cursor: default;
}
