#rm-header header {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
  cursor: default;
}
#rm-header header > div {
  width: 100%;
}
#rm-header .logo {
  height: 40px;
}
#rm-header .logo a {
  display: inline-block;
  height: 40px;
}
#rm-header .logo svg {
  height: 40px;
  width: 40px;
  transition: transform 0.6s ease-in-out;
}
#rm-header .avatar {
  height: 40px;
  display: inline-block;
}
#rm-header .avatar a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  background-color: #f4f4f4;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.04px;
  color: #282828;
  text-transform: uppercase;
  font-family: Graphik;
}
#rm-header .avatar a.with-avatar {
  background-color: transparent;
}
#rm-header .links {
  text-align: center;
}
#rm-header .links a {
  display: inline-block;
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-right: 16px;
  letter-spacing: -0.1px;
  text-decoration: none;
  color: #000;
}
.text-on-dark #rm-header .links a {
  color: #fff;
}
.white-theme.scrolled #rm-header .links a {
  color: #000 !important;
}
.white-theme.scrolled #rm-header .links a:hover,
.white-theme.scrolled #rm-header .links a.active {
  color: #ec520b !important;
}
#rm-header .links a:last-child {
  margin-right: 0;
}
#rm-header .links a:hover {
  color: #ec520b;
}
#rm-header .links a.active {
  color: #ec520b;
}
#rm-header .buttons {
  text-align: right;
  font-size: 0;
}
#rm-header .buttons a {
  background-color: rgba(244, 244, 244, 0.96);
  border-radius: 200px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.1px;
  font-size: 16px;
  display: inline-block;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  color: #000;
}
#rm-header .buttons a:hover {
  color: #fff;
  background-color: #ec520b;
}
.rm-header-mobile #rm-header header {
  align-items: center;
  padding: 6px 6px;
}
.rm-header-mobile #rm-header header .avatar {
  width: auto;
  height: 48px;
}
.rm-header-mobile #rm-header header .avatar a {
  height: 48px;
  width: 48px;
}
.rm-header-mobile #rm-header header .logo {
  height: 48px;
}
.rm-header-mobile #rm-header header .logo a {
  height: 48px;
}
.rm-header-mobile #rm-header header .logo svg {
  height: 48px;
  width: 48px;
}
.rm-header-mobile #rm-header header .links {
  display: none;
}
.rm-header-mobile #rm-header header .buttons {
  display: none;
}
@media screen and (max-width: 768px) {
#rm-header header {
    align-items: center;
    padding: 6px 6px;
}
#rm-header header .avatar {
    width: auto;
    height: 48px;
}
#rm-header header .avatar a {
    height: 48px;
    width: 48px;
}
#rm-header header .logo {
    height: 48px;
}
#rm-header header .logo a {
    height: 48px;
}
#rm-header header .logo svg {
    height: 48px;
    width: 48px;
}
#rm-header header .links {
    display: none;
}
#rm-header header .buttons {
    display: none;
}
header.menu {
    height: 60px !important;
}
.widget-rm-header {
    width: 100% !important;
    left: 0 !important;
    top: 0 !important;
    margin-left: 0 !important;
}
.login-popup {
    top: 0 !important;
}
}
