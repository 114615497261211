.spinner {
  position: relative;
  margin: 14px 0 11px -5px;
  height: 19px;
  letter-spacing: -0.1px;
}
.spinner .spin-title {
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 12px;
  color: #5d5d5d;
  text-align: center;
  padding-top: 4px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  cursor: default;
}
.spinner .spin {
  position: absolute;
  width: 12px;
  height: 20px;
  top: 0;
  cursor: pointer;
}
.spinner .spin.left {
  left: 2px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/audio_settings/spin-l.png);
}
.spinner .spin.left:hover {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/audio_settings/spin-l-hover.png);
}
.spinner .spin.right {
  right: 1px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/audio_settings/spin-r.png);
}
.spinner .spin.right:hover {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/audio_settings/spin-r-hover.png);
}
.spinner .fade-enter-active,
.spinner .fade-leave-active {
  transition: 0.3s ease;
}
.spinner .fade-enter {
  transform: translateX(15px);
  opacity: 0;
}
.spinner .fade-enter-to {
  transform: translateX(0px);
  opacity: 1;
}
.spinner .fade-leave-to {
  opacity: 0;
  transform: translateX(-15px);
}
.spinner.negative .fade-enter {
  transform: translateX(-15px);
}
.spinner.negative .fade-leave-to {
  transform: translateX(15px);
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
.spinner .spin {
    background-size: 12px 20px;
}
.spinner .spin.left {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/audio_settings/spin-l@2x.png);
}
.spinner .spin.left:hover {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/audio_settings/spin-l-hover@2x.png);
}
.spinner .spin.right {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/audio_settings/spin-r@2x.png);
}
.spinner .spin.right:hover {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/audio_settings/spin-r-hover@2x.png);
}
}
