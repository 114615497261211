.info[data-v-a35d3dbc] {
  position: absolute;
  top: 2px;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  display: inline-block;
  vertical-align: middle;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: 0.15s border-color ease-in-out;
}
.info[data-v-a35d3dbc]::after {
  content: '?';
  position: absolute;
  top: 0;
  left: 0;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.3);
  transition: 0.15s color ease-in-out;
}
.info[data-v-a35d3dbc]:hover {
  border-color: white;
}
.info[data-v-a35d3dbc]:hover::after {
  color: white;
}
