.video-panel-search {
  margin-bottom: 12px;
}
.video-panel-search .video-panel-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
  margin: auto;
  right: 8px;
  cursor: pointer;
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/widgetbar/background/search.svg) no-repeat 0 0;
  opacity: 0.4;
  background-size: 16px 16px !important;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}
.video-panel-search .video-panel-icon:hover {
  opacity: 0.6;
}
.video-panel-search .video-panel-icon.is-upload {
  right: 7px;
  width: 18px;
  height: 18px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-upload.png);
  background-size: 18px 18px !important;
}
.video-panel-search .video-panel-icon.is-upload:hover {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-upload-hover.png);
}
.video-panel-search .video-panel-icon.is-remove {
  right: 7px;
  width: 18px;
  height: 18px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/delete.png);
  background-size: 18px 18px !important;
}
.video-panel-search .video-panel-icon.is-remove:hover {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/delete-hover.png);
}
.video-panel-search .video-panel-icon.hidden {
  display: none;
}
.video-panel-input-wrapper {
  position: relative;
  width: 100%;
  height: 36px;
}
.video-panel-input {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 36px;
  padding: 5px 9px;
  border: none;
  border-radius: 8px;
  font: inherit;
  background: white;
  color: inherit;
  -moz-appearance: none;
}
.video-panel-input::-webkit-input-placeholder {
  color: rgba(151, 158, 168, 0.5);
}
.video-panel-input:-moz-placeholder {
  color: rgba(151, 158, 168, 0.5);
}
.video-panel-input.has-icon {
  padding-right: 30px;
}
.video-panel-error {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1px;
  left: -1px;
  padding: 1px;
  border-radius: 8px;
  background: #f2f2f2;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #5d5d5d;
  letter-spacing: -0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}
.video-panel-social {
  margin-top: 16px;
  font-size: 0;
  text-align: center;
}
.video-panel-social-icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0 6px;
  cursor: pointer;
  -webkit-transition: 0.3s opacity ease-in-out, 0.15s background ease-out;
  transition: 0.3s opacity ease-in-out, 0.15s background ease-out;
}
.video-panel-social-youtube {
  left: 0;
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-youtube.png) no-repeat 0 0;
  background-size: 32px 32px !important;
}
.video-panel-social-youtube:hover {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-youtube-hover.png);
}
.video-panel-social-vimeo {
  left: 32px;
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-vimeo.png) no-repeat 0 0;
  background-size: 32px 32px !important;
}
.video-panel-social-vimeo:hover {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-vimeo-hover.png);
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
.video-panel-search .video-panel-input-wrapper .video-panel-icon.is-upload {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-upload@2x.png);
}
.video-panel-search .video-panel-input-wrapper .video-panel-icon.is-upload:hover {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-upload-hover@2x.png);
}
.video-panel-search .video-panel-input-wrapper .video-panel-icon.is-remove {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/delete@2x.png);
}
.video-panel-search .video-panel-input-wrapper .video-panel-icon.is-remove:hover {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/delete-hover@2x.png);
}
.video-panel-search .video-panel-social-youtube {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-youtube@2x.png);
}
.video-panel-search .video-panel-social-youtube:hover {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-youtube-hover@2x.png);
}
.video-panel-search .video-panel-social-vimeo {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-vimeo@2x.png);
}
.video-panel-search .video-panel-social-vimeo:hover {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/video_settings/social-vimeo-hover@2x.png);
}
}
