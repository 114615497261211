.addtocart-settings-panel {
  width: 168px;
  height: 360px;
  box-sizing: border-box;
  padding: 13px 15px;
  right: 47px !important;
  top: -112px;
  border-radius: 8px;
  background-color: rgba(244, 244, 244, 0.96);
  border: 1px solid rgba(64, 64, 64, 0.08);
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Ubuntu, 'Fira Sans', Roboto, 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  color: #282828;
}
.addtocart-settings-panel.settings-panel-long {
  height: 400px;
}
.addtocart-settings-panel .title-row {
  font-size: 16px;
}
.addtocart-settings-panel .title-row .header {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.addtocart-settings-panel .title-row .title {
  position: relative;
  top: -4px;
}
.addtocart-settings-panel .title-row .layout-controls {
  height: 12px;
  width: 72px;
  display: flex;
  justify-content: space-between;
}
.addtocart-settings-panel .title-row .layout-controls .layout-btn {
  width: 32px;
  height: 100%;
}
.addtocart-settings-panel .title-row .layout-controls .layout-btn.active .layout-icon rect {
  fill: #282828;
}
.addtocart-settings-panel .title-row .layout-controls .layout-btn:hover {
  cursor: pointer;
}
.addtocart-settings-panel .title-row .layout-controls .layout-btn.horizontal-layout {
  position: relative;
  top: -6px;
}
.addtocart-settings-panel .title-row .layout-controls .layout-btn.vertical-layout {
  position: relative;
  top: -2px;
}
.addtocart-settings-panel .title-row .layout-controls .layout-btn .layout-icon rect {
  fill: rgba(40, 40, 40, 0.4);
}
.addtocart-settings-panel .styling-state-switcher {
  margin-top: 24px;
  margin-bottom: 13px;
}
.addtocart-settings-panel .styling-state-switcher .btn {
  color: #808080;
}
.addtocart-settings-panel .styling-state-switcher .btn:hover {
  cursor: pointer;
}
.addtocart-settings-panel .styling-state-switcher .btn.active {
  color: #282828;
}
.addtocart-settings-panel .styling-state-switcher .btn + span {
  margin-left: 8px;
}
.addtocart-settings-panel .settings-panel .row {
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.addtocart-settings-panel .settings-panel .row:last-child {
  border-bottom: none;
}
.addtocart-settings-panel .settings-panel .row .color-picker-container.round {
  width: 26px;
  height: 26px;
}
.addtocart-settings-panel .settings-panel .row .num-input-wrap .num-input {
  padding: 0;
  border: 0;
  font-size: 24px;
  color: #282828;
  background: transparent;
  text-align: right;
  cursor: ns-resize;
  opacity: 1;
  transition: all 0.1s ease-out;
}
.addtocart-settings-panel .settings-panel .row .num-input-wrap .num-input.hidden-num-input {
  opacity: 0;
}
.addtocart-settings-panel .settings-panel .row .num-input-wrap .disabled-numeric {
  position: absolute;
  top: 17px;
  right: 0;
  display: none;
}
.addtocart-settings-panel .settings-panel .row .num-input-wrap .disabled-numeric.show-disabled-icon {
  display: block;
}
.addtocart-settings-panel .settings-panel .row .num-input {
  padding: 0;
  border: 0;
  font-size: 24px;
  color: #282828;
  background: transparent;
  text-align: right;
  cursor: ns-resize;
  opacity: 1;
  transition: all 0.1s ease-out;
}
.addtocart-settings-panel .settings-panel .row .num-input.hidden-num-input {
  opacity: 0;
}
.addtocart-settings-panel .settings-panel .row .disabled-numeric {
  display: none;
}
.addtocart-settings-panel .settings-panel .row .disabled-numeric.show-disabled-icon {
  display: block;
}
.addtocart-settings-panel .settings-panel .row .font-family-container .expand-arrow {
  width: 7px;
  height: 12px;
  background-size: 12px 7px;
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/add_to_cart_settings/expand-arrow.svg);
  flex: 0 0 12px;
  position: relative;
  top: 3px;
  left: 1px;
}
.addtocart-settings-panel .settings-panel .row .font-family-container.container-disabled:hover .expand-arrow {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/add_to_cart_settings/expand-arrow.svg);
}
.addtocart-settings-panel .settings-panel .row .font-family-container:hover .expand-arrow {
  background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/add_to_cart_settings/expand-arrow-active.svg);
}
