.rm-native-select-container[data-v-6de76536] {
  width: 100%;
  height: 24px;
  position: relative;
  margin: 0 auto;
}
.rm-native-select-container .rm-native-select[data-v-6de76536] {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
}
.rm-native-select-container .rm-native-fake-select[data-v-6de76536] {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  vertical-align: middle;
  text-overflow: ellipsis;
  background-color: transparent;
  font-size: 16px;
  cursor: default;
  user-select: none;
  outline: none;
  border-style: none;
  resize: none;
}
.rm-native-select-container .expand-arrow[data-v-6de76536] {
  position: absolute;
  top: 5px;
  right: 0;
}
.rm-native-select-container .rm-native-select-popup[data-v-6de76536] {
  width: 100%;
  min-height: 24px;
  max-height: 256px;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  text-align: left;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.rm-native-select-container .rm-native-select-popup.opened[data-v-6de76536] {
  opacity: 1;
  visibility: visible;
}
.rm-native-select-container .rm-native-select-popup .rm-native-select-popup-item[data-v-6de76536] {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 8px 12px;
}
.rm-native-select-container .rm-native-select-popup .rm-native-select-popup-item.current[data-v-6de76536] {
  background-color: rgba(255, 255, 255, 0.2);
}
