.apply-input .input {
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: #979ea8;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
  border: none;
  line-height: 25px;
  padding: 2px 25px 1px;
}
.apply-input .input.no-prefix {
  padding: 2px 26px 1px 8px;
}
.apply-input .input::-webkit-input-placeholder {
  color: #979ea8;
  opacity: 0.5;
}
.apply-input .input:-moz-placeholder {
  color: #979ea8;
  opacity: 0.5;
}
.apply-input .input-prefix {
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  color: #979ea8;
  top: 9px;
  position: absolute;
  left: 8px;
}
.apply-input .input-control {
  position: absolute;
  top: 5px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  right: 6px;
  -webkit-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out;
}
.apply-input .input-control.clear {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-delete.png) no-repeat 0 0;
}
.apply-input .input-control.clear:hover {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-delete-hover.png) no-repeat 0 0;
}
.apply-input .input-control.apply {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-go.png) no-repeat 0 0;
}
.apply-input .input-control.apply:hover {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-go-hover.png) no-repeat 0 0;
}
.apply-input .input-control.hidden {
  display: none;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
.apply-input .input-control.clear {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-delete@2x.png) no-repeat 0 0;
    background-size: 18px 18px;
}
.apply-input .input-control.clear:hover {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-delete-hover@2x.png) no-repeat 0 0;
    background-size: 18px 18px;
}
.apply-input .input-control.apply {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-go@2x.png) no-repeat 0 0;
    background-size: 18px 18px;
}
.apply-input .input-control.apply:hover {
    background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/panels/twitter/input-go-hover@2x.png) no-repeat 0 0;
    background-size: 18px 18px;
}
}
