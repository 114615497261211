.size-tool {
  white-space: nowrap;
  background: #f2f2f2;
  border-radius: 8px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  z-index: 2505;
  padding: 6px 8px 6px 8px;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.minimal-constructor-ui .size-tool {
  opacity: 0;
  visibility: hidden;
}
.size-tool span {
  color: #b3b3b3;
  user-select: none;
  background: #f2f2f2;
  font-size: 16px;
  display: inline-block;
  margin: 3px -4px;
  vertical-align: middle;
}
.size-tool input {
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  outline: none;
  resize: none;
  background-color: transparent;
  font-size: 16px;
  display: inline-block;
  color: #0078ff;
  border: none;
  text-align: center;
  font-family: 'Avenir Next', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  vertical-align: middle;
  letter-spacing: -0.5px;
}
.size-tool input:disabled {
  color: #b3b3b3;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.size-tool .width {
  margin-right: -1px;
}
.size-tool .height {
  margin-left: -1px;
}
