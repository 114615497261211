/**
 * z-indeces
 */
.no-transitions {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}
.border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.stretched-block {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.input-reset {
  padding: 0;
  margin: 0;
  overflow-y: hidden;
  overflow-x: hidden;
  outline: none;
  border: none;
  resize: none;
  background-color: transparent;
}
.sticky-position {
  position: -webkit-sticky;
  position: sticky;
}
.panel-style {
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.04), 0 0 0 3px rgba(255, 255, 255, 0.28);
  background: rgba(243, 243, 243, 0.98);
}
.nowrap {
  white-space: nowrap;
}
.rotate_cycle {
  -webkit-animation: spin 0.8s infinite linear;
  animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
0% {
    -webkit-transform: rotate(0deg);
}
100% {
    -webkit-transform: rotate(360deg);
}
}
@keyframes spin {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}
.animation-shake {
  -webkit-animation: shake 0.5s linear;
  animation: shake 0.5s linear;
}
@-webkit-keyframes shake {
8%,
  41% {
    -webkit-transform: translateX(-10px);
}
25%,
  58% {
    -webkit-transform: translateX(10px);
}
75% {
    -webkit-transform: translateX(-5px);
}
92% {
    -webkit-transform: translateX(5px);
}
0%,
  100% {
    -webkit-transform: translateX(0);
}
}
@keyframes shake {
8%,
  41% {
    transform: translateX(-10px);
}
25%,
  58% {
    transform: translateX(10px);
}
75% {
    transform: translateX(-5px);
}
92% {
    transform: translateX(5px);
}
0%,
  100% {
    transform: translateX(0);
}
}
.cursor-grab {
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
}
.cursor-grabbing {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.fade-enter,
.fade-leave-to {
  opacity: 0 !important;
  visibility: hidden;
}
.fade-enter-active {
  -webkit-transition: opacity 0.1s ease-out, visibility 0s;
  transition: opacity 0.1s ease-out, visibility 0s;
}
.fade-leave-active {
  -webkit-transition: opacity 0.1s ease-out, visibility 0s ease-out 0.1s;
  transition: opacity 0.1s ease-out, visibility 0s ease-out 0.1s;
}
.fix-radius-clip {
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
}
.separator-filled {
  width: 100%;
  height: 2px;
  background-color: rgba(184, 184, 184, 0.24);
}
.separator-dotted {
  width: 100%;
  height: 2px;
  background: center center url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/dots_divider.svg) repeat-x;
  box-sizing: border-box;
}
.controls .control .rmheader_settings {
  height: auto;
  top: -66px;
  box-sizing: border-box;
  width: 167px;
  right: 47px;
  padding: 10px 16px 26px;
  border-radius: 9px;
  background-color: #f2f2f2;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.0666);
  letter-spacing: -0.08px;
  line-height: 16px;
  font-size: 12px;
  font-weight: 500;
  color: #282828;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
.controls .control .rmheader_settings .param-caption {
  font-weight: 500;
  text-align: left;
  padding-bottom: 15px;
  color: #282828;
  font-size: 16px;
  letter-spacing: -0.4px;
  line-height: 20px;
  position: relative;
  left: 0;
  width: 100%;
}
.controls .control.rmheader_settings .icon {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/shape_settings/icon.png) no-repeat 0 0, url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/arrow.png) no-repeat 30px 0;
}
.controls .control.rmheader_settings .icon:hover {
  background: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/shape_settings/icon-hover.png) no-repeat 0 0, url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/arrow-hover.png) no-repeat 30px 0;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
.controls .control.rmheader_settings .icon {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/shape_settings/icon@2x.png), url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/arrow@2x.png);
    background-size: 30px 30px, 30px 30px;
}
.controls .control.rmheader_settings .icon:hover {
    background-image: url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/shape_settings/icon-hover@2x.png), url(https://d1id5eheivyv24.cloudfront.net/d8f374b7/dist/img/constructor/controls/arrow-hover@2x.png);
    background-size: 30px 30px, 30px 30px;
}
}
